$color-success: #5ed71c;
$color-grey-grey-3: #f3f5f8;
$color-white: #ffffff;
$color-grey-grey-1: #505d6f;
$color-grey-grey-2: #edf0f4;
$color-blue-hover: #0f35ce;
$color-blue-main: #003074;
$color-blue-wallet: #151b38;
$color-blue-wallet: #151b38;
$color-disable: #eff1ff;

$themes: (
  light: (
    backgroundColor: #ffffff,
    textColor: #191d2b,
  ),
  dark: (
    backgroundColor: #050505,
    textColor: #ffffff,
  ),
);

$theme-map: null;

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
