$container-max-widths: (
  xs: 320px,
  sm: 540px,
  md: 770px,
  //hide menu
  lg: 960px,
  xl: 1440px,
);

@import "~bootstrap/scss/bootstrap";
@import "themes.scss";

$container-width: 1440px;
$container-padding: 132px;

@mixin container-padding {
  padding-left: $container-padding;
  padding-right: $container-padding;
}

@mixin font-size($size) {
  font-size: ($size / 16px) * 1rem;
}

@mixin with-bottom-border-separator() {
  border-bottom: 1px solid #e0e6ed;
}

@mixin h1() {
  font-size: 51px;
  color: #3b4859;
  line-height: normal;
  font-weight: 700;

  span {
    color: #2333ff;
  }
}

html {
  height: 100%;
}
body {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  @include font-size(14px);
  line-height: normal;
  letter-spacing: 0.35px;
  overflow-x: hidden;

  a {
    color: #1441f4;
  }

  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("backgroundColor");
  }

  .with-bottom-border-separator {
    @include with-bottom-border-separator;
  }

  &.error-message {
    .wallet-bg {
      .section-1 {
        .bottom-sub-title {
          margin-bottom: 30px;

          + .try-again-button {
            display: inline-block;
            text-transform: uppercase;
            font-weight: 800;
            margin-bottom: 62px;
          }
        }
      }
    }
  }
  &.loading {
    .wallet-bg {
      .section-1 {
        .content {
          display: none;
        }
        .loader {
          display: block;
        }
      }
    }
  }

  .loader {
    display: none;

    .middle-sub-title,
    .bottom-sub-title {
      border-radius: 12px;
      background-size: 200% 200%;
      background-position: 0% 50%;
      display: block;
      width: 344px;
      height: 40px;

      background-image: linear-gradient(
        to right,
        #c5d0fd -76%,
        #f9fafe 83%,
        #c5d0fd 125%
      );
    }

    .bottom-sub-title {
      width: 100%;
      height: 20px;
    }
  }

  .generic-button {
    width: 116px;
    height: 48px;
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    transition: all 0.2s ease-in-out;

    a {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 12px;
    }

    &.primary {
      background-color: $color-blue-main;
      border: 1px solid $color-blue-main;
      color: $color-white;
    }

    &.white {
      background-color: $color-white;
      border: 1px solid $color-white;
      color: $color-blue-main;

      a {
        color: $color-blue-main;
      }
    }

    &:hover,
    &:active {
      background: #ffffff;
      color: $color-blue-main;
      border-color: $color-blue-hover;
      transition: all 0.3s ease-in-out;

      a {
        color: $color-blue-main;
        text-decoration: none;
      }
    }
    &:active {
      border-color: $color-blue-main;
    }

    &.outline {
      border: 1px solid white;
    }
  }

  .container {
    padding: 0;

    #card-container {
      display: none;
    }

    &.pending-card {
      #card-container {
        display: inline-block;
      }
    }
  }

  .payments-bg {
    background: $color-blue-main;
  }

  .wallet-bg {
    background: $color-white;

    &-image {
      max-width: 540px;
      max-height: 700px;
      position: absolute;
      right: 130px;
      top: 0;
    }
  }

  .custom-container {
    width: 100%;
    max-width: 1440px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .custom-small-container {
    width: 100%;
    height: calc(100% - 126px);
    max-width: 1024px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .custom-md-container {
    width: 100%;
    max-width: 1000px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .logo-sub-text {
    margin-top: 7px;
  }

  input,
  textarea {
    border-radius: 6px;
    border: solid 1px #dde3e8;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;

    &::placeholder {
      color: #8492a5;
    }
  }

  header {
    background: $color-white;

    .header-top {
      @include with-bottom-border-separator;
      font-size: 10px;
      height: 32px;
    }
    .header-main {
      padding-top: 14px;
      padding-bottom: 14px;

      .header-logo {
        width: 90px;
        height: 26px;
        object-fit: contain;
      }
      .navbar {
        padding: 5px;

        &-inline {
          .navbar-brand {
            margin-right: 40px;
          }
          .nav-item {
            .nav-link {
              padding-left: 24px;
              padding-right: 24px;

              font-weight: 800;
              font-size: 13px;
              line-height: 24px;
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: #262626;
            }
            &.active {
              font-weight: 700;
            }
            &.nav-item-contact-us {
              display: none;
            }
          }
        }
      }
      .navbar-brand {
        margin-right: 32px;
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .navbar-brand {
          display: none;
        }
      }
    }
    .dropdown-item {
      padding: 0.8rem 1.5rem;
    }
  }

  .section-1 {
    display: flex;
    padding-top: 60px;
    align-items: center;
    height: 100%;

    > .row {
      width: 100%;
    }

    .charge-logo {
      display: inline-block;
      width: 277px;
      height: 92px;
      margin-bottom: 72px;
      display: none;
    }

    .top-sub-title {
      font-size: 14px;
      font-weight: bold;
      color: $color-blue-main;
      text-transform: uppercase;
    }
    .title {
      @include h1();
    }
    .bottom-sub-title {
      font-size: 18px;
      color: #8492a6;
    }
    .generic-button {
      width: 150px;
      height: 44px;
    }
    .generic-button.try-again-button {
      display: none;
    }
  }

  .section-9 {
    padding-top: 36px;
    padding-bottom: 36px;
    border-bottom: 1px solid #e0e6ed;

    img {
      width: 132px;
      height: 44px;
    }

    .logo-sub-text {
      font-size: 12px;
      color: #3b4858;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > a {
        color: #8592ab;
        margin-left: 20px;
      }
    }
  }

  .section-10 {
    height: 48px;
    padding: 0;

    line-height: 22px;
    letter-spacing: 0.5px;

    color: #262626;
    opacity: 0.5;
    font-weight: 500;
    font-size: 13px;

    &-section-10-right {
      text-align: right;
    }
  }

  .terms-container {
    &-title {
      font-size: 31px;
      font-weight: 700;
      color: #3b4859;
      margin-bottom: 24px;
    }
    &-sub-title {
      font-size: 14px;
      font-weight: 700;
      color: #3b4858;
      margin-bottom: 14px;
    }
    &-text {
      color: #8592a9;
      font-size: 14px;
      line-height: 1.14;
      margin-bottom: 28px;

      ul {
        padding: 0 0 0 15px;
        margin: 28px 0 0;
        list-style: none;

        li {
          margin-bottom: 14px;

          &::before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: $color-blue-main; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 10px; /* Also needed for space (tweak if needed) */
            height: 10px; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
            margin-right: 10px;
          }
        }
      }
    }
    &-section {
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 120px;
      }
    }
    .table thead th {
      border: none;
      font-size: 12px;
      font-weight: 600;
      color: #8592a9;
      text-transform: uppercase;
    }
    .table th,
    .table td {
      height: 68px;
      vertical-align: middle;
    }
  }

  &.redeem {
    // background: #f3f7f9;

    .navbar-light .navbar-nav .nav-link {
      color: #191d2b;
      font-weight: 800;
      font-size: 12px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    .section-1 {
      padding-top: 0;

      img {
        height: auto;
        max-height: none;
        max-width: none;
      }
      .header-img-container {
        padding-top: 0;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
      .bottom-sub-title {
        margin-bottom: 64px;
      }
    }
    .wallet-bg {
      position: relative;

      .phone-mockup {
        position: absolute;
        right: -20%;
        top: 10%;
        width: 100%;
        height: 100%;
        max-width: 832px;
        max-height: 675px;
      }

      &-image {
        height: 100%;
        max-height: none;
      }
      .container {
        position: relative;
        z-index: 1;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .section-1 {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
        }
      }
      .header-img-container {
        display: inline-block;
        position: relative;
        height: 545px !important;
        width: 273px !important;

        &.theme-bg-light {
          .card-img {
            .card-name {
              filter: invert(1);
            }
            .card-provider-visa {
              filter: invert(1);
            }
            .card-dots {
              filter: invert(1);
            }
          }
        }

        .card-img {
          width: 215px;
          height: 143px;
          background: transparent;
          position: absolute;
          right: 29px;
          top: 82px;

          .card-name {
            position: absolute;
            top: 16px;
            left: 14px;
            width: 125px;
            color: white;
            text-align: left;
            font-size: 12px;
            font-weight: 600;
          }
          .card-bg {
            height: 100%;
          }
          .card-provider {
            position: absolute;
            bottom: 14px;
            right: 14px;

            width: 48px;
            height: 18px;
          }
          .card-provider-mastercard {
            display: none;
            height: 30px;
            width: auto;
            bottom: 9px;
          }
          .card-dots-container {
            position: absolute;
            bottom: 13px;
            left: 14px;
            width: auto;

            .card-dots {
              height: 18px;

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }

        .card-balance {
          background: white;
          position: absolute;
          right: 43px;
          top: 253px;
          width: 100px;
          height: 20px;
          text-align: left;
          font-size: 9px;
          font-weight: bold;
          color: black;
          display: block;
          align-items: center;
        }
        .card-note {
          background: #eff2ff;
          position: absolute;
          right: 43px;
          top: 278px;
          width: 186px;
          height: 150px;
          text-align: left;
          font-size: 9px;
          font-weight: bold;
          color: black;
          border-radius: 5px;
          overflow-y: scroll;
          padding: 5px;
        }
        .loader {
          height: 466px;
          width: 238px;
          position: absolute;
          top: 40px;
          left: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #faf9ff;
          background: -webkit-linear-gradient(top, #faf9ff, #edf0fb);
          background: -moz-linear-gradient(top, #faf9ff, #edf0fb);
          background: linear-gradient(to bottom, #faf9ff, #edf0fb);
        }
        .loader img {
          width: 32px;
          height: 32px;
        }
      }

      .header-img {
        height: 545px;
        width: 273px;
      }
      .middle-sub-title {
        font-weight: 800;
        font-size: 40px;
        color: #003074;
        letter-spacing: -1.3px;

        margin-bottom: 32px;
      }
      .bottom-sub-title {
        font-size: 18px;
        font-weight: 800;
        line-height: 27px;
        letter-spacing: 0.7px;
        color: #191d2b;
      }
      .markets-container {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.7px;
        color: #191d2b;

        &-links {
          margin-top: 20px;

          > a {
            display: inline-block;

            img {
              width: 135px;
              height: 39px;
            }

            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }

      .add-to-chrome {
        margin-top: 20px;
        display: none;

        &.show {
          display: block;
        }

        > button {
          margin-top: 10px;
          background-color: #3871e0;
          color: #fff;
          outline: none;
          border: none;
          width: 140px;
          height: 35px;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    .section-9 {
      padding-top: 0;
      padding-bottom: 10px;

      img {
        width: 73px;
        height: 24px;
      }

      .logo-sub-text {
        color: #191d2b;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1px;
      }

      &-right {
        > a {
          color: #191d2b;
          font-weight: 800;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.8px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    height: 100%;

    .custom-small-container {
      height: calc(100% - 163px);
    }

    .phone-mockup {
      display: none;
    }
    .navbar-toggler {
      background: white;
    }
    .navbar-collapse {
      border-bottom: 1px solid #e0e6ed;

      .nav-link {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    .container {
      max-width: initial;
      padding: 15px;
    }
    .custom-container {
      padding: 0;

      .row {
        > div {
          padding: 0;
        }
      }
    }
    header {
      .header-main {
        padding-top: 0;
        .header-logo {
          width: 104px;
          height: 34px;
        }
      }
    }
    .section-9 {
      padding-top: 24px;
      padding-bottom: 0;

      .row {
        > div {
          text-align: center;
          margin-bottom: 15px;
        }
      }
      &-right {
        flex-direction: column;

        > a {
          margin-bottom: 10px;
        }
      }
    }
    .section-10 {
      padding-top: 24px;
      padding-bottom: 24px;
      text-align: center;

      .row {
        > div {
          margin-bottom: 10px;
        }
      }
    }

    &.redeem {
      .wallet-bg-image {
        display: none;
      }
      .section-1 {
        .header-img-container {
          display: block;
          padding-top: 0;
          height: 545px !important;
          width: 273px !important;
          margin: 25px auto;
        }
        .row {
          flex-direction: column-reverse;
        }
      }
      .header-img {
        height: 545px !important;
        width: 273px !important;
      }
    }
  }
}

$breakpoint-nav: 991px;
@media (max-width: $breakpoint-nav) {
  body {
    header {
      .header-main-left {
        display: none;
      }
      .header-main-right {
        align-items: flex-start;
        justify-content: space-between;

        .navbar-collapse {
          .nav-link {
            padding-top: 15px;
            padding-bottom: 15px;
            background: rgb(245, 245, 247);
          }
        }

        .generic-button.contact-us-button {
          display: none;
        }

        > .navbar-brand {
          display: inline-block;
        }

        > .navbar {
          justify-content: flex-end;
        }

        .navbar-nav {
          .nav-item.nav-item-contact-us {
            display: inline-block;
          }
        }
      }
    }
  }
}
